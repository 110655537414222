<template>
  <div class="admin-nft-page">
    <admin-nav-bar></admin-nav-bar>
    <div class="container">
      <h1>인기 NFT 권한 부여</h1>
      <span
        >JellyCrew 인기 NFT(CCCV_JELLYCREW_MAIN) 권한을 부여하는
        메뉴입니다.</span
      >
      <p>
        <strong>JellyCrew NFT 목록</strong>에서 원하는 NFT를 인기 NFT로
        추가하세요.
      </p>
      <div class="jellycrew-nfts">
        <h2>JellyCrew 인기 NFT 목록</h2>
        <table>
          <tr>
            <th>_id</th>
            <th>제목</th>
            <th>판매상태</th>
            <th>제작자</th>
            <th>제작자 아이디</th>
            <th>생성 시간</th>
            <th>상세 보기</th>
            <th>인기NFT 등록</th>
          </tr>
          <tr v-if="popularityNftList.length === 0">
            <td colSpan="8">
              검색 결과가 없습니다.
            </td>
          </tr>
          <tr v-for="item in popularityNftList" v-bind:key="item._id">
            <td>{{ item.aergoGroupId }}</td>
            <td>{{ item.title }}</td>
            <td>{{ convertSaleStatus(item.isOnSale) }}</td>
            <td>{{ item.creator_name }}</td>
            <td>{{ item.creator_id }}</td>
            <td>{{ convertDate(item.createdAt) }}</td>
            <td>
              <button class="table-btn" @click="onClickInformation(item)">
                보기
              </button>
            </td>
            <td>
              <button
                class="table-btn danger"
                @click="onClickRemoveMainNft(item)"
              >
                해제
              </button>
            </td>
          </tr>
        </table>
      </div>
      <div class="jellycrew-nfts">
        <h2>JellyCrew NFT 목록</h2>
        <table>
          <tr>
            <th>_id</th>
            <th>제목</th>
            <th>판매상태</th>
            <th>제작자</th>
            <th>제작자 아이디</th>
            <th>생성 시간</th>
            <th>상세 보기</th>
            <th>인기NFT 등록</th>
          </tr>
          <tr v-if="nftList.length === 0">
            <td colSpan="8">
              검색 결과가 없습니다.
            </td>
          </tr>
          <tr v-for="item in nftList" v-bind:key="item._id">
            <td>{{ item.aergoGroupId }}</td>
            <td>{{ item.title }}</td>
            <td>{{ convertSaleStatus(item.isOnSale) }}</td>
            <td>{{ item.creator_name }}</td>
            <td>{{ item.creator_id }}</td>
            <td>{{ convertDate(item.createdAt) }}</td>
            <td>
              <button class="table-btn" @click="onClickInformation(item)">
                보기
              </button>
            </td>
            <td>
              <span v-if="item.tags.some(o => o === '@CCCV_JELLYCREW_MAIN')"
                >등록됨</span
              >
              <button
                v-else
                class="table-btn danger"
                @click="onClickRegisterMainNft(item)"
              >
                등록
              </button>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <modal v-if="showModal" @close="handleCloseModal">
      <template v-slot:header>
        <h1>NFT 정보</h1>
      </template>
      <template v-slot:body>
        <h3>등록자 정보</h3>
        <p>{{ `${modalData.name}(${modalData.creator_id})` }}</p>
        <h3>NFT 이름</h3>
        <p>{{ modalData.title }}</p>
        <h3>NFT 발행수</h3>
        <p>{{ modalData.amount }}</p>
        <h3>NFT 소개</h3>
        <p v-html="modalData.description" />
        <h3>NFT 이미지</h3>
        <p><img class="modal-img" :src="modalData.external_url" /></p>
      </template>
    </modal>
  </div>
</template>

<script>
import {
  getNfts,
  getMainNfts,
  registerMainNft,
  removeMainNft
} from "@/common/api.service";
import { checkAdmin } from "@/common/auth.service";
import AdminNavBar from "@/components/admin/Navbar";
import Modal from "@/components/admin/Modal";

const PER_PAGE = 100;

export default {
  name: "Nft",
  components: {
    AdminNavBar,
    Modal
  },
  data() {
    return {
      popularityNftList: [],
      nftList: [],
      modalData: {},
      showModal: false
    };
  },
  async created() {
    const isAdmin = await checkAdmin();

    if (!isAdmin) {
      this.$router.push("/");
    }

    this.initialize();
  },
  methods: {
    initialize() {
      this.getJellycrewNfts();
      this.getJellycrewMainNfts();
    },
    getJellycrewNfts() {
      getNfts().then(res => {
        if (res && res.data) {
          this.nftList = res.data;
        }
      });
    },
    getJellycrewMainNfts() {
      getMainNfts("", PER_PAGE).then(res => {
        if (res && res.data) {
          this.popularityNftList = res.data;
        }
      });
    },
    convertSaleStatus(isOnSale) {
      return isOnSale ? "O" : "X";
    },
    convertDate(date) {
      return new Date(date).toLocaleString();
    },
    handleCloseModal() {
      this.showModal = false;
      this.modalData = {};
    },
    onClickInformation(data) {
      this.showModal = true;
      this.modalData = data;
    },
    onClickRegisterMainNft(data) {
      registerMainNft(data.aergoGroupId).then(res => {
        if (res && res.data) {
          this.initialize();
        }
      });
    },
    onClickRemoveMainNft(data) {
      if (!window.confirm("정말 해제하시겠습니까?")) {
        return;
      }
      removeMainNft(data.aergoGroupId).then(res => {
        if (res && res.data) {
          this.initialize();
        }
      });
    }
  }
};
</script>

<style scoped>
.admin-nft-page {
  display: flex;
  border-top: 1px solid #f1f1f1;
}
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.modal-img {
  max-width: 600px;
}
</style>
